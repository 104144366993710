<template>
  <div class="apply-page">
    <fm-title title-text="报废申请" :titleMenus="titleMenus" @clickTitleMenu="clickTitleMenu">
      <div class="title-sort">
        <div class="fm-tabs fm-tabs-norm">
          <div class="fm-tabs-nav">
            <div @click="dealStatus = item.key" class="fm-tabs-nav-item" :class="{'fm-tabs-nav-active': item.key === dealStatus}" v-for="item in dealSwitch" :key="item.key">
              {{item.label}}
            </div>
          </div>
        </div>
      </div>
    </fm-title>
    <deal-block
      class="p-d-s"
      v-if="dealStatus === 'wait_deal' && !batchDeal && waitDealDataList.length > 0"
      :dataList="waitDealDataList"
      :statusMap="statusMap"
      :orgList="orgList"
      :typeList="typeList"
      @dataUpdate="dataUpdate"
      :assetsList="assetsList">
    </deal-block>
    <batch-deal-block
      class="p-d-s"
      v-else-if="dealStatus === 'wait_deal' && batchDeal && waitDealDataList.length > 0"
      :typeList="typeList"
      :dataList="waitDealDataList"
      @dataUpdate="dataUpdate">
    </batch-deal-block>
    <list-info
      class="p-d-s"
      v-else-if="dealStatus !== 'wait_deal' && (dealStatus === 'dealed' ? dealedDataList : dataList).length > 0"
      :dataList="dealStatus === 'dealed' ? dealedDataList : dataList"
      :config="config"
      :statusMap="statusMap"
      @dataUpdate="dataUpdate">
    </list-info>
    <div class="p-d-s-n" v-else>
      <div>暂无数据</div>
    </div>
    <detail-form :typeList="typeList" :orgList="orgList" @dataUpdate="dataUpdate" :assetsList="assetsList" ref="dForm" :data="chooseData"></detail-form>
  </div>
</template>

<script>
import detailForm from './apply/form'
import dealBlock from './apply/dealBlock'
import batchDealBlock from './apply/batchDealBlock'
import listInfo from './apply/list'

import {
  assetsScrapApplyRequest,
  assetsRequest,
  statusManageRequest
} from '../../api'

export default {
  components: {
    detailForm,
    dealBlock,
    batchDealBlock,
    listInfo
  },
  data () {
    return {
      config: [],
      dealSwitch: [{key: 'wait_deal', label: '待处理'}, {key: 'dealed', label: '已处理'}, {key: 'all', label: '全部'}],
      dealStatus: 'wait_deal',
      typeList: [{key: 'norm', label: '正常报废'}, {key: 'abnormal', label: '异常报废'}],
      batchDeal: false,
      chooseData: null,
      dataList: [],
      statusMap: {},
      assetsList: []
    }
  },
  created () {
    this.$store.dispatch('loadOrgList')
    this.$store.dispatch('loadUserList')
    this.loadConfig()
  },
  activated () {
    this.init()
  },
  computed: {
    orgList () {
      let allData = this.$store.getters.orgList
      let myOrgIds = this.$store.getters.currentOrgId ? [this.$store.getters.currentOrgId] : []
      return allData.filter(v => myOrgIds.length === 0 ||  myOrgIds.includes(v.key))
    },
    waitDealDataList () {
      return this.dataList.filter(v => v.actions && v.actions.filter(v1 => v1.key !== 'back_status_switch').length > 0)
    },
    dealedDataList () {
      return this.dataList.filter(v => !v.actions || v.actions.filter(v1 => v1.key !== 'back_status_switch').length === 0)
    },
    titleMenus () {
      return this.$authFunsProxy.add ? [{key: 'batchDeal', label: '批量操作'}, {key: 'add', label: '添加报废申请'}] : [{key: 'batchDeal', label: '批量操作'}]
    }
  },
  methods: {
    async loadConfig () {
      let c = await statusManageRequest.config({
        statusGroupKey: 'assetsScrapApply'
      })
      c.statusList.forEach(v => {
        this.statusMap[v.statusKey] = v.label
      })
      this.config = c.statusSwitchList.filter(v => v.isHost)
    },
    async dataUpdate () {
      await this.loadData()
    },
    async init () {
      this.dealStatus = 'wait_deal'
      this.batchDeal = false
      this.chooseData = null
      this.dataList = []
      this.loadData()
      this.loadAssetsList()
    },
    async loadData () {
      let datas = await assetsScrapApplyRequest.getByAuth({
        statusActionRecord: 1,
        isNotEnd: 1
      })
      datas.forEach(v => {
        v.fileIds = (v.fileIds || '').split(',').filter(v => v).map(v => Number(v))
        v.typeLabel = (this.typeList.find(v1 => v1.key === v.type) || {label: ''}).label
      })
      this.dataList = datas
    },
    async loadAssetsList () {
      let datas = await assetsRequest.get()
      this.assetsList = datas
    },
    clickTitleMenu (key) {
      if (key === 'add') {
        if (this.orgList.length === 0) {
          this.$notify({
            title: '系统提示',
            message: '未配置科室权限',
            type: 'warning'
          })
          return
        }
        this.chooseData = {}
        this.$refs.dForm.modal = true
      } else if (key === 'batchDeal') {
        if (this.dealStatus !== 'wait_deal') {
          this.dealStatus = 'wait_deal'
          this.batchDeal = true
        } else {
          this.batchDeal = !this.batchDeal
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.apply-page {
  background: #fff;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  margin: 10px;
  .title-sort {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .p-d-c {
    width: calc(100% - 40px);
    height: calc(100% - 88px);
  }
  .p-d-s {
    width: 100%;
    height: calc(100% - 48px);
  }
  .p-d-s-n {
    width: 100%;
    height: calc(100% - 48px);
    font-size: 20px;
    color: #515a6e;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      padding: 20px;
    }
  }
  .left {
    height: calc(100% - 20px);
    overflow-y: auto;
    width: 300px;
    border-right: 1px solid #e8eaec;;
    padding-right: 20px;
  }
  .right {
    height: calc(100% - 20px);
    flex: 1;
    overflow-y: auto;
    border-top: 1px solid #e8eaec;;
    border-right: 1px solid #e8eaec;;
    border-bottom: 1px solid #e8eaec;;
    .right-btns {
      padding:10px 20px;
      display: flex;
      justify-content: space-around;
    }
  }
  .sign-item {
    margin-bottom: 10px;
  }
}
</style>