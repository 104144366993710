<template>
  <fm-modal title="报废申请" v-model="modal" width="800" v-if="modal">
    <div class="p-detial-from">
      <div class="right">
        <!-- <fm-title titleText="采购明细"></fm-title> -->
        <fm-form label-align="left">
          <fm-form-item label="申请科室" v-if="orgList.length > 1">
            <fm-select absolute filterable :clearable="true" v-model="formData.applyOrgId">
              <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="报废资产">
            <fm-select absolute filterable :clearable="true" v-model="formData.assetsId">
              <fm-option v-for="item in assetsList" :key="item.id" :value="item.id" :label="item.code + item.name"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="报废类型">
            <fm-select absolute filterable :clearable="true" v-model="formData.type">
              <fm-option v-for="item in typeList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="说明">
            <fm-input-new type="remark" v-model="formData.remark"></fm-input-new>
          </fm-form-item>
        </fm-form>
        <div class="model-btn">
          <fm-btn style="margin-right: 30px;" @click="formSubmit">保存</fm-btn>
          <fm-btn @click="modal = false">取消</fm-btn>
        </div>
      </div>
    </div>
  </fm-modal>
</template>

<script>
import {
  assetsScrapApplyRequest
} from '../../../api'

export default {
  props: {
    data: { type: Object, defualt: () => null },
    orgList: { type: Array, defualt: () => [] },
    typeList: { type: Array, defualt: () => [] },
    assetsList: { type: Array, defualt: () => [] },
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : {
          total: ''
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      formData: {},
      searchText: '',
      step: 0,
      modal: false,
      loading: false
    }
  },
  methods: {
    async formSubmit () {
      if (this.loading) {
        return
      }
      if (!this.formData.applyOrgId) {
        if (this.orgList.length === 1) {
          this.formData.applyOrgId = this.orgList[0].key
        } else {
          this.$notify({
            title: '系统提示',
            message: '请选择申请科室',
            type: 'info'
          })
          return
        }
      }
      if (!this.formData.assetsId) {
        this.$notify({
          title: '系统提示',
          message: '请选择报废资产',
          type: 'info'
        })
        return
      }
      this.loading = true
      let data = this.formData
      if (data.id) {
        await assetsScrapApplyRequest.update(data.id, data)
      } else {
        await assetsScrapApplyRequest.add(data)
      }
      this.$emit('dataUpdate', 'add')
      this.modal = false
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.p-detial-from {
  display: flex;
  .center {
    max-height: 100%;
    overflow-y: auto;
    width: 380px;
    padding: 0 20px;
  }
  .left {
    flex: 1;
    border-radius: 5px;
  }
  .left-a {
    border: 1px solid #e8eaec;
    border-radius: 5px;
    flex: 1;
    display: flex;
  }
  .right {
    margin-right: 20px;
    width: 100%;
  }
  .purchase-batch-detail-list-search {
    margin-left: 10px;
  }
  .purchase-batch-detail-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    max-height: calc(100% - 80px);
    overflow-y: auto;
  }
  .purchase-batch-detail-item {
    background: #e8eaec;
    color: #515a6e;
    line-height: 28px;
    padding: 0 10px;
    border-radius: 5px;
    margin: 10px 0 0 10px;
  }
  .purchase-batch-detail-item-a {
    background: #F4628F;
    color: #fff;
  }
}
.model-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>